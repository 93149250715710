@media (max-width: 500px) {
  .navbarLogoContainer {
    margin: auto;
    width: 250px;
  }
}

@media (min-width: 200px) {
  .navbarText {
    display: unset !important;
    margin-left: 35px;
  }
}

.menu_bar {
  margin-bottom: 0;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.mainHeaderStyle {
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 0;
  padding-right: 0;
}

.navbarLogo {
  width: 250px;
  height: 100%;

}

.floatRight {
  float: right;
}