@media (max-width: 992px) {
  .socialLinksArea {
    justify-content: center;
  }

  .socialLinksContainer {
    padding-top: 10px;
  }
}

@media (min-width: 992px) {
  .socialLinksArea {
    justify-content: flex-end;
  }
}

.footerLogoArea {
  width: 100%;
  height: 43px;
}

.footerLogo {
  margin-top: 5px;
  height: 27px;
  width: 350px;
  margin-left: auto;
}

.socialLinksArea {
  text-align: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: 0;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
  flex-direction: row;
}

.socialMediaLink {
  vertical-align: bottom;
  float: right;
  width: 35px;
  height: 100%;
  padding-bottom: 10px;
}

.socialMediaLink img {
  width: 100%;
}

.footerStayConnected {
  width: 200px;
  float: left;
  float: right;
  margin-right: 10px;
  padding-bottom: 10px;
}