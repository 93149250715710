@media (min-width: 577px) {
  .footerLinks {
    width: 50%;
  }
}

@media (max-width: 992px) {
  .footerLinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.footerLinks {
  padding-left: 0;
  background-color: transparent;
  background-size: cover;
  list-style-type: none;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.linkHeader {
  color: #fff;
  font-weight: bold;
  font-size: 22px;
}

.link {
  color: #9d9d9d;
  text-decoration: none;
}

.link:hover {
  color: #fff;
}

.linkButton:hover {
  text-decoration: underline;
}