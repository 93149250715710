.gallery {
    width: 100%;
    border-collapse: collapse;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 20px;
    max-height: 700px;
}

.image-gallery-slide {
    border: 2px solid black;
    border-radius: 5px;
    padding: 10px;
}

.image-gallery-slide img {
    max-height: 400px !important;
}