.header {
  box-shadow: 0px 2px 10px 0px #bbbbbb;
}

.alert {
  margin-bottom: 0 !important;
  font-family: 'Lato', sans-serif;
}

.alertText {
  max-width: 1000px;
  margin: auto;
  padding-left: 10px;
}