.tableContainer {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.table thead {
  background-color: #00638d;
  color: #fff;
}

.table th,
.table td {
  padding: 15px;
  text-align: left;
}

.table td {
  border-bottom: 1px solid #ecf0f1;
  margin-bottom: 5px;
}

.table td:last-child {
  margin-bottom: 0;
}

.table tbody tr:hover {
  background-color: #f2f2f2;
}

.search {
  margin: 0px 0 20px 0;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ecf0f1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.search input {
  width: 100%;
  padding: 5px;
  border: none;
  outline: none;
}

.modal {
  border: 1px solid #000;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  max-width: 600px;
  max-height: 800px;
}

.modal img {
  max-width: 500px;
  max-height: 500px;
}

.overlay {
  z-index: 999;
  position: fixed;
}

.action button {
  float: right;
  background-color: #00638d;
  border: none;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
}

.action button:hover {
  background-color: #00506b;
}