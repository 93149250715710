.body {
  padding-top: 30px;
  padding-bottom: 50px;
  padding-left: 0;
  padding-right: 0;
}

.content {
  height: 200px;
}

.headerText {
  display: block;
  font-weight: bold;
  color: #ffffff;
  font-size: 28px;
  text-shadow: 1px 1px 2px #000000;
  margin-bottom: 20px;
}

@media (max-width: 500px) {
  .body {
    padding: 20px;
  }

  .headerText {
    color: #000000;
    text-shadow: none;
  }
}